<template>
  <div>
    <!-- <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入banner名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div> -->

    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id" width="100px"></el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>
      <el-table-column prop="appVer" label="version"></el-table-column>
      <el-table-column prop="platform" label="platform"></el-table-column>
      <el-table-column label="config" min-width="500px">
        <template slot-scope="scope">
          <span>{{scope.row.config}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="100px">
        <template slot-scope="scope">
          <span>{{scope.row.status===1?'上架':'下架'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="warning" 
            @click="unmountFunc(scope.row)"
            >{{scope.row.status===1?'下架':'上架'}}</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog :title="titleText" :visible.sync="addVisible" width="600px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <template v-if="titleText=='审核开关'">
          <el-form-item label="审核开关" prop="config.openAudit" :rules="rules.changeRule">
            <el-switch
              v-model="ruleForm.config.openAudit"
              active-color="#13ce66"
              inactive-color="#dcdfe6"
              active-text="开"
              inactive-text="关">
            </el-switch>
          </el-form-item>
        </template>
        <template v-else-if="titleText=='通话扣费配置'">
          <el-form-item label="分钟花费" prop="config.callCost" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.callCost"
              type="number"
            ></el-input>
          </el-form-item>
        </template>
        <template v-else-if="titleText=='腾讯配置'">
          <el-form-item label="腾讯sdkappId" prop="config.tencentSdkAppId" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.tencentSdkAppId"
            ></el-input>
          </el-form-item>
          <el-form-item label="推送证书" prop="config.tencentAndroidPushCertificateID" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.tencentAndroidPushCertificateID"
            ></el-input>
          </el-form-item>
          <el-form-item label="推送ios正式" prop="config.tencentIosPushCertificateID" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.tencentIosPushCertificateID"
            ></el-input>
          </el-form-item>
        </template>
       
        <template v-else-if="titleText=='钱包配置'">
          <el-form-item label="nft合约" prop="config.nftContractAddress" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.nftContractAddress"
            ></el-input>
          </el-form-item>
          <el-form-item label="质押合约" prop="config.pledgeContractToken" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.pledgeContractToken"
            ></el-input>
          </el-form-item>
          <el-form-item label="升级合约" prop="config.upgradeContractToken" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.upgradeContractToken"
            ></el-input>
          </el-form-item>
          <el-form-item label="繁育合约" prop="config.breedContractToken" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.breedContractToken"
            ></el-input>
          </el-form-item>
          <el-form-item label="道具合约" prop="config.blindBoxContractToken" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.blindBoxContractToken"
            ></el-input>
          </el-form-item>
          <el-form-item label="装饰合约" prop="config.decorateContractToken" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.decorateContractToken"
            ></el-input>
          </el-form-item>
          <el-form-item label="任务合约" prop="config.taskContractToken" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.taskContractToken"
            ></el-input>
          </el-form-item>
          <el-form-item label="afa合约" prop="config.afaToken" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.afaToken"
            ></el-input>
          </el-form-item>
          <el-form-item label="talk合约" prop="config.tTalkToken" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.tTalkToken"
            ></el-input>
          </el-form-item>
          <el-form-item label="提现合约" prop="config.doWithdrawContractToken" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.doWithdrawContractToken"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司钱包地址" prop="config.companyWalletAddress" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.companyWalletAddress"
            ></el-input>
          </el-form-item>
          <el-form-item label="链id" prop="config.nftChainIndex" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.nftChainIndex"
            ></el-input>
          </el-form-item>
          <el-form-item label="链名" prop="config.nftChainName" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.nftChainName"
            ></el-input>
          </el-form-item>
        </template>
        
        <template v-else-if="titleText=='通用配置'">
          <el-form-item label="downloadURL" prop="config.downloadURL" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.downloadURL"
            ></el-input>
          </el-form-item>
          <el-form-item label="tramsURL" prop="config.tramsURL" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.tramsURL"
            ></el-input>
          </el-form-item>
          <el-form-item label="privacyPolicyURL" prop="config.privacyPolicyURL" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.privacyPolicyURL"
            ></el-input>
          </el-form-item>
          <el-form-item label="userPolicyURL" prop="config.userPolicyURL" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.userPolicyURL"
            ></el-input>
          </el-form-item>
          <el-form-item label="nftTransactionURL" prop="config.nftTransactionURL" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.nftTransactionURL"
            ></el-input>
          </el-form-item>
          <el-form-item label="riskURL" prop="config.riskURL" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.riskURL"
            ></el-input>
          </el-form-item>
          <el-form-item label="securityAuditURL" prop="config.securityAuditURL" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.securityAuditURL"
            ></el-input>
          </el-form-item>
          <el-form-item label="nftMintURL" prop="config.nftMintURL" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.nftMintURL"
            ></el-input>
          </el-form-item>
          <el-form-item label="taskURL" prop="config.taskURL" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.taskURL"
            ></el-input>
          </el-form-item>
          <el-form-item label="roomId" prop="config.roomId" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.roomId"
            ></el-input>
          </el-form-item>
          <el-form-item label="adminUserId" prop="config.adminUserId" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.adminUserId"
            ></el-input>
          </el-form-item>
          <el-form-item label="sensorsUrl" prop="config.sensorsUrl" :rules="rules.blurRule">
            <el-input
              placeholder="请输入内容"
              v-model="ruleForm.config.sensorsUrl"
            ></el-input>
          </el-form-item>
        </template>

        <el-form-item label="平台">
          <el-radio-group v-model="ruleForm.platform">
            <el-radio label="ios">ios</el-radio>
            <el-radio label="android">android</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="版本号">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.appVer"
          ></el-input>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {},
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
      titleText: '',
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
  },
  methods: {
    title(row) {
      // OPEN_AUDIT --审核开关  CALL_COST_CONFIG --通话扣费配置  TX_CONFIG -- 腾讯配置  WALLET_CONFIG -- 钱包配置  COMMON_CONFIG -- 通用配置
      if(row.type=='OPEN_AUDIT') { 
        return '审核开关'
      } else if(row.type=='CALL_COST_CONFIG') {
        return '通话扣费配置'
      } else if(row.type=='TX_CONFIG') {
        return '腾讯配置'
      } else if(row.type=='WALLET_CONFIG') {
        return '钱包配置'
      } else if(row.type=='COMMON_CONFIG') {
        return '通用配置'
      }
    },
    addFunc(){
      this.ruleForm = {}
      this.addVisible = true;
    },
    toEdit(data) {
      this.titleText = this.title(data)
      this.ruleForm = {...data,config:{...JSON.parse(data.config)}};
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let ruleForm = {...this.ruleForm}
          ruleForm.config = JSON.stringify(ruleForm.config)
          let res = await this.$http.appConfigSave({...ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.appConfigList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.appConfigDel({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    unmountFunc(row) {
      this.$confirm(`确定要${row.status?'下架':'上架'}么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.appConfigSave({ ...row , status:row.status==0?1:0 })
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.el-form .el-input{
  width: 400px !important;
}
</style>
